import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { BaseTestChart } from './pages/baseTestChart';
import { Default } from './pages/default';


const router = createBrowserRouter([
  {
    path: "/indicator/:indicatorId/country/:countryId",
    element: <BaseTestChart />,
  },
  {
    path: "*",
    element: <Default />,
  },
]);

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}
      {/* <BaseTestChart /> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
