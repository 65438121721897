import { Button, Grid, IconButton, TextField } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';


export const Header = () => {

    return (
        <Grid container pl='60px' pt='10px' pb='10px'>
            <img src="https://ru.statbase.org/upload/CPriority/8ad/eirbhhxit174ieq0i8z26k8e1212041o/statbase%203.png" height={'30px'} />
        </Grid>
    );
}
