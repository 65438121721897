import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { isColor } from "../../utils/chart.utils";

export const useBaseTestChart = (data, params, chartName) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/xy.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        // strArray.push(`\troot.setThemes([
        //     am5themes_Animated.new(root)
        // ]);\r\n`);
        strArray.push(`\tvar chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ${params.zoomEnable ?
                `wheelX: "panX",
                wheelY: "zoomX",
                ` : ''}layout: root.verticalLayout
            })
        );\r\n`);

        if (params.scrollbarEnable) {
            strArray.push(`\tchart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));\r\n`);
        }

        strArray.push(`\tlet cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));\r\n`);
        strArray.push(`\tcursor.lineY.set("visible", false);\r\n`);

        strArray.push(`\tvar jsonData = '${JSON.stringify(data)}';\r\n`);
        strArray.push(`\tvar data = JSON.parse(jsonData);\r\n`);
        strArray.push(`\tvar yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );\r\n`);
        strArray.push(`\tvar xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "year"
            })
        );\r\n`);
        strArray.push(`\txAxis.data.setAll(data);\r\n`);
        strArray.push(`\tvar series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "year",
                minBulletDistance: 50,
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{categoryX}: {valueY}"
                })
            })
        );\r\n`);
        strArray.push(`\tseries1.data.setAll(data);\r\n`);

        if (isColor(params.color)) {
            strArray.push(`\tseries1.set("fill", am5.color('${params.color}'));\r\n`);
            strArray.push(`\tseries1.set("stroke", am5.color('${params.color}'));\r\n`);
        }

        strArray.push(`\tvar yRenderer = yAxis.get("renderer");\r\n`);
        strArray.push(`\tyRenderer.labels.template.setAll({
            fontSize: "0.7em",
            rotation: -60,
            centerY: am5.p50
        });\r\n`);

        strArray.push(`\tseries1.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });\r\n`);
        strArray.push(`\tseries1.columns.template.set("interactive", true);\r\n`);

        if (isColor(params.hoverColor)) {
            strArray.push(`\tseries1.columns.template.states.create("hover", {
                fill: am5.color('${params.hoverColor}'),
                stroke: am5.color('${params.hoverColor}')
            });\r\n`);
        }

        strArray.push(`\tseries1.appear(500);\r\n`);

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [data, params]);

    return { htmlDiagram, createChart, chartName }
}

export const useGetData = (indicatorId, countryId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_ax_graph_country.php?indicator=${indicatorId}&country=${countryId}&couname=statbase_graph25179_5977`, [indicatorId, countryId]);

    useEffect(() => {
        if (!indicatorId || !countryId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, countryId]);

    return { data, isLoading }
}

export const useChartParams = () => {

    const [params, setParams] = useState({
        color: '#67b7dc',
        hoverColor: '#297373',
        zoomEnable: true,
        scrollbarEnable: true,
        width: "100%",
        height: "400px",
    });

    const changeTextField = (name, event) => {
        const value = event.target.value;
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeBoolField = (name) => {
        setParams((prev) => ({ ...prev, [name]: !prev[name] }))
    }

    return { changeTextField, changeBoolField, params }
}

export const useSliderParams = (data) => {

    const [yearRange, setYearRange] = useState(null);

    const { min, max, marks } = useMemo(() => {
        const values = data?.map((item) => Number(item.year)) ?? [];
        const min = Math.min(...values);
        const max = Math.max(...values);
        const marks = [{ value: min, label: min }, { value: max, label: max }];
        return { min, max, marks }
    }, [data]);

    useEffect(() => {
        if (!min || !max) return;
        setYearRange([min, max]);
    }, [min, max]);

    const changeSlider = (event) => {
        const value = event.target.value;
        setYearRange(value);
    }

    return { min, max, marks, yearRange, changeSlider }
}