import { useLayoutEffect, useMemo } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Checkbox, Grid, LinearProgress, Slider, TextField } from "@mui/material";
import { useBaseTestChart, useChartParams, useGetData, useSliderParams } from "./baseTestChart.hooks";
import { CopyHtmlGrid } from "../../components/copyHtmlGrid";
import { Header } from "../../components/header";
import { paramsSx } from "./baseTestChart.styles";
import { useParams } from "react-router-dom";
import { nanoid } from 'nanoid';
import { isColor } from "../../utils/chart.utils";


export const BaseTestChart = () => {
    const { indicatorId, countryId } = useParams();
    const { data: loadedData } = useGetData(indicatorId, countryId);
    const { changeTextField, changeBoolField, params } = useChartParams();
    const { min, max, yearRange, marks, changeSlider } = useSliderParams(loadedData);

    const chartName = useMemo(() => `statbase_${nanoid(10)}`, []);

    const filteredData = useMemo(() => {
        if (!yearRange || !loadedData) return loadedData;
        const [minYear, maxYear] = yearRange;
        const filteredByYear = loadedData.filter((item) => Number(item.year) > minYear && Number(item.year) < maxYear);
        return filteredByYear;
    }, [loadedData, yearRange]);

    const { htmlDiagram, createChart } = useBaseTestChart(filteredData, params, chartName);


    useLayoutEffect(() => {
        if (!filteredData) return;
        let root = am5.Root.new(chartName);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ...(params.zoomEnable ? {
                    wheelX: "panX",
                    wheelY: "zoomX",
                } : {}),
                pinchZoomX: true,
                layout: root.verticalLayout
            })
        );

        if (params.scrollbarEnable) {
            chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));
        }

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "year"
            })
        );
        xAxis.data.setAll(filteredData);

        // Create series
        let series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "year",
                minBulletDistance: 50,
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{categoryX}: {valueY}"
                })
            })
        );
        series1.data.setAll(filteredData);

        if (isColor(params.color)) {
            series1.set("fill", am5.color(params.color));
            series1.set("stroke", am5.color(params.color));
        }

        var yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fontSize: "0.7em",
            rotation: -60,
            centerY: am5.p50
        });

        // series1.bullets.push(function () {
        //     var label = am5.Label.new(root, {
        //         text: "{valueY}",
        //         fill: series1.get("fill"),
        //         centerX: am5.p50,
        //         centerY: am5.p100,
        //         populateText: true
        //     })
        //     label.adapters.add("centerY", function (centerY, target) {
        //         var dataItem = target.dataItem;
        //         if (dataItem) {
        //             var value = dataItem.get("valueY", 0)
        //             if (value < 0) {
        //                 return 0
        //             }
        //         }
        //         return centerY;
        //     })
        //     return am5.Bullet.new(root, {
        //         locationY: 1,
        //         sprite: label
        //     });
        // });

        series1.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });
        series1.columns.template.set("interactive", true);

        if (isColor(params.hoverColor)) {
            series1.columns.template.states.create("hover", {
                fill: am5.color(params.hoverColor),
                stroke: am5.color(params.hoverColor)
            });
        }

        series1.appear(500);

        // const jsonSerializer = am5plugins_json.Serializer.new(root, {});
        // const jsonData = jsonSerializer.serialize(data, 0, true);
        // console.log("🚀 ~ useLayoutEffect ~ jsonData:", JSON.stringify(jsonData))
        // series1.set("fill", am5.color('#67b7dc'));

        // let series2 = chart.series.push(
        //     am5xy.ColumnSeries.new(root, {
        //         name: "Series",
        //         xAxis: xAxis,
        //         yAxis: yAxis,
        //         valueYField: "value2",
        //         categoryXField: "category"
        //     })
        // );
        // series2.data.setAll(data);

        // Add legend
        // let legend = chart.children.push(am5.Legend.new(root, {}));
        // legend.data.setAll(chart.series.values);

        // Add cursor
        // chart.set("cursor", am5xy.XYCursor.new(root, {}));

        return () => {
            root.dispose();
        };
    }, [filteredData, params]);

    return (
        <>
            <Header />
            {!filteredData && <LinearProgress />}
            {filteredData && <Grid container paddingTop={'15px'} paddingLeft='60px' paddingRight='60px' flexDirection='column' gap='10px' mb='30px'>
                <Grid container fontSize='36px' fontWeight='600' mb='30px'>Настройки графика</Grid>
                <Grid container sx={paramsSx}>
                    <Grid container gap='10px' alignItems='baseline'>
                        Цвет <TextField size="small" value={params.color} onChange={(e) => changeTextField('color', e)} />
                    </Grid>
                    <Grid container gap='10px' alignItems='baseline'>
                        Цвет при наведении <TextField size="small" value={params.hoverColor} onChange={(e) => changeTextField('hoverColor', e)} />
                    </Grid>
                    <Grid>
                        Включить масштабирование по оси X <Checkbox checked={params.zoomEnable} onClick={() => changeBoolField('zoomEnable')} />
                    </Grid>
                    <Grid>
                        Отображать полосу прокрутки <Checkbox checked={params.scrollbarEnable} onClick={() => changeBoolField('scrollbarEnable')} />
                    </Grid>
                    <Grid container gap='10px' alignItems='baseline'>
                        Ширина <TextField size="small" value={params.width} onChange={(e) => changeTextField('width', e)} />
                    </Grid>
                    <Grid container gap='10px' alignItems='baseline'>
                        Высота <TextField size="small" value={params.height} onChange={(e) => changeTextField('height', e)} />
                    </Grid>
                    {!!yearRange && <Grid container gap='30px' alignItems='center'>
                        Диапазон <Slider
                            sx={{ width: '400px' }}
                            min={min}
                            max={max}
                            value={yearRange}
                            onChange={changeSlider}
                            marks={marks}
                            valueLabelDisplay="auto"
                        />
                    </Grid>}
                    {/* <Grid width={'300px'}><Slider min={1997} max={2024} value={[2014, 2020]} /></Grid> */}
                </Grid>
                {/* <Divider orientation="horizontal" /> */}
                <Grid width={'100%'} container gap={'10px'}>
                    <div id={chartName} style={{ width: params.width, height: params.height }}></div>
                    <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
                </Grid>
            </Grid>}
        </>
    );
}

