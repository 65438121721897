import { Button, Grid, IconButton, TextField } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';


export const Default = () => {

    return (
        <Grid container height='100vh' width='100vw' justifyContent='center' alignItems='center' flexDirection='column' gap='30px'>
            <img src="https://ru.statbase.org/upload/CPriority/8ad/eirbhhxit174ieq0i8z26k8e1212041o/statbase%203.png" height={'30px'} />
            <Grid fontSize='30px' fontWeight='600'>Упс... Кажется что-то пошло не так</Grid>
        </Grid>
    );
}
